<template>
	<Page class="page-password-reset-confirm l--content">
		<Title title="Please check your email" />

		<p class="page-password-reset-confirm__description">
			We have sent an email with a password reset link to your email address.
			In order to complete the process, please click the link.
		</p>
		<p class="page-password-reset-confirm__description">
			If you do not receive an email, please check your spam folder.
			Also, please verify that you entered a valid email address in our password reset form.
		</p>
		<p class="page-password-reset-confirm__description">
<!--			TODO: contact us link to mail or page -->
			If you need assistance, please contact us.
		</p>
	</Page>
</template>

<script>
	import Page from "../components/layouts/LayoutPage";
	import Title from "../components/common/Title";

	export default {
		name: 'PasswordResetConfirm',
		components: { Page, Title },
	};
</script>

<style lang="scss" scoped>
	.page-password-reset-confirm {
		&__description {
			text-align: center;
			font-size: 1.6rem;
			margin-bottom: 3rem !important;
			@include breakpoint(overPhone) {
				@include center-block;
				max-width: 50rem;
			}
		}
	}
</style>
